import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getNavigationMessage } from "../../../components/src/Navigation.web"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface MyProps {
  navigation: any;
  id: string;
  classes: any;
  children?: React.ReactNode;
}

export interface MyState {
  activeTab: string;
  needHelp: boolean;
  profileLogoutProfile: boolean;
  userData: {
    first_name: string;
    profile_picture?: string;
  };
  name: string;
}
export interface S {
  // Customizable Area Start
  activeTab: string;
  profileLogoutProfile: boolean,
  name: string,
  needHelp: boolean;
  userData:any;
  formData: {
    name: string;
    email: string;
    query: string;
  };
  errors: {
    email: string;
  };
  helpmodal:boolean;
  helpmodalstate:string;  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SideMenuBarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileCall: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      // Customizable Area Start
      activeTab: "Dashboard",
      profileLogoutProfile: false,
      name: "",
      needHelp: false,
      userData:null,
      formData: {
        name: '',
        email: '',
        query: ''
      },
      errors: {
        email: ''
      } ,
      helpmodal:false,
      helpmodalstate:"0" 
          // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (this.userProfileCall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        let data = apiResponse.data.attributes.first_name == null ? "" : apiResponse.data.attributes.first_name;
        localStorage.setItem("username", data)

        this.setState({
          name: data,
          userData:apiResponse.data.attributes
        })
      }
      else {
        this.send(getNavigationMessage('EmailAccountLoginBlock', this.props));
      }

    }


    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<MyProps>, prevState: Readonly<MyState>) {

    if (prevState.activeTab !== this.state.activeTab) {
      window.scrollTo(0, 0);
    }
    const activetab = this.props.navigation.history.location.pathname.split("/")[2]
    if(activetab === "Residential Status" && activetab !== this.state.activeTab){
      this.setState({
        activeTab: activetab,
      })
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    this.userProfileName()
    const activetab = this.props.navigation.history.location.pathname.split("/")[2] + "/" + this.props.navigation.history.location.pathname.split("/")[3];
    if (this.props.navigation.history.location.pathname.split("/")[2] === "Capitalgains" || this.props.navigation.history.location.pathname.split("/")[2] === "Businessincome") {
      this.setState({
        activeTab: activetab,
      })
    } else {
      this.setState({
        activeTab: (this.props.navigation.history.location.pathname).split("/")[2],
      })
    }
  }

  userProfileName = () => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profiledataGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleCloseNeedHelp = () => {
    this.setState({
      needHelp: false
    });
  }
  changeTab = (name: string, needhelp = false) => {

    this.setState({
      activeTab: name,
      needHelp: needhelp
    });
    // this.props.navigation.history.pop(`Menu/${name}`)

    this.props.navigation.history.push(`/Menu/${name}`)
  };
  navigateToLandingPage = () => {
    this.send(getNavigationMessage('LandingPage', this.props));
  }
  handleprofilelogout = () => {
    this.setState({ profileLogoutProfile: !this.state.profileLogoutProfile })
  }
  profilepage = () => {
    this.send(getNavigationMessage('UserProfileBasicBlock', this.props));

  }
  logoutpage = () => {
    this.send(getNavigationMessage('LandingPage', this.props));
    localStorage.clear()
  }
  handlesetActiveTab(text: any) {
    if (text.name === this.state.activeTab) {
      return 'activeTab'
    } else if (this.state.activeTab.split("/")[0].replace(/l/g, 'l ') === text.name) {
      return 'activeTab'
    } else if (this.state.activeTab.split("/")[0].replace(/ss/g, 'ss ') === text.name) {
      return 'activeTab'
    } else {
      return "listItem"
    }
  }

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: ''
      }
    }));
  };

   validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};
  
  handleSubmit = () => {
    const { formData } = this.state;

    if (!this.validateEmail(formData.email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: 'Invalid email address'
        }
      }));
    }

    if (this.validateEmail(formData.email)) {
      this.setState({helpmodal:false,helpmodalstate:"0"})
    }
  };

  handleclose = () => {
    this.setState({
      helpmodal: false,
      helpmodalstate:"0"
    })
  }

  handleopen = () => {
    this.setState({
      helpmodal: true,
    })
  }

  handleincresestate=()=>{
    this.setState({helpmodalstate:"1"})
  }

  // Customizable Area End
}

