import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Switch,
  InputAdornment,
  withStyles,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createTheme,
   ThemeProvider,
    Tooltip
} from "@material-ui/core";
import {
  profileIconimage,
  pancard,
  lock,
  hamburger,
  paper,
  edit,
  image_edit,
  Shield,
} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from '@material-ui/lab/Alert';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import BackgroundHeaderWeb from "../../../components/src/BackgroundHeader.web";
import CloseIcon from '@material-ui/icons/Close';
import OTPInput from "../../../components/src/OTPInput.web";
import UserProfileBasicController, {
  Props,
  configJSON
} from "./UserProfileBasicController.web";
import MakeaQuery from "../../landingpage/src/MakeaQuery.web";
const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50', // Green color
    },
  },
});
export class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }
  renderprofileData = (classes: any) => {
    return (
      <Box>
        {!this.state.enableEditMode ? (
          <Grid container className={classes.userIcon}>
            <Grid item xs={12} sm={2} className={classes.responsive}>
              <img src={this.state.profilePicture == null ? profileIconimage : this.state.profilePicture}  className={classes.imageRadius} />
            </Grid>
            <Grid item sm={8} className={classes.userData}>
              <Box className={classes.infowrap}>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >
                  {configJSON.name}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >
                  {configJSON.contact}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >
                  {configJSON.email}
                </Typography>
              </Box>
              <Box className={classes.userAPiData}>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >
                  {this.state.name ?? configJSON.noName}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >

                  {this.state.phoneNumber ?? configJSON.noName}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.userDataProfileText}
                >
                  {this.state.email}
                </Typography>
              </Box>
            </Grid>

            <Grid item sm={2} xs={6} className={classes.iconeEdit}>
              <img src={edit}
                alt={configJSON.profile_icon}
                data-test-id="edit_profile"
                className={classes.docimgstyle}
                onClick={this.editProfileEnable}
              />
            </Grid>
          </Grid>
        ) : (
          <Box className={classes.userEditPage}>
            <Grid container className={classes.gridWrapper}>
              <Box >
                {<img src={ this.state.profilePhoto ? this.state.previewSource :
                  profileIconimage}
                  alt={configJSON.profile_icon} className={classes.imageRadiusEdit} />}
                <Box>
                  <input
                    type="file"
                    ref={(fileInput: any) => (this.fileInput = fileInput)}
                    data-testid="file-input"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange}
                    data-test-id="file_handle"
                  />
                  <img src={image_edit} alt={configJSON.profile_icon} className={classes.imageEditIcon} onClick={this.handleIconClick} data-test-id="image_change" />
                </Box>
              </Box>
              <Grid item xs={6}>
                <Typography className={classes.subHeading}>
                  {configJSON.name}
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder={configJSON.placeHolderName}
                  type="text"
                  value={this.state.name}
                  name="name"
                  data-test-id="name"
                  onChange={this.handleNameChange}
                  onFocus={this.handleFocusEdit}
                  className={classes.textField}
                />
                <Box>
                  {this.state.errors.name ? (
                    <Typography className={classes.errorMessage}>
                      {this.state.errors.name}
                    </Typography>
                  ) : null}
                </Box>
                <Typography className={classes.subHeading}>
                  {configJSON.contact}
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder={configJSON.placeHolderMobile}
                  type="text"
                  value={this.state.phoneNumber}
                  name="phoneNumber"
                  data-test-id="phoneNumber"
                  onChange={this.handlePhoneChange}
                  onFocus={this.handleFocusEdit}
                  className={classes.textField}
                />
                <Box>
                  {this.state.errors.phoneNumber ? (
                    <Typography className={classes.errorMessage}>
                      {this.state.errors.phoneNumber}
                    </Typography>
                  ) : null}
                </Box>
                <Typography className={classes.subHeading}>
                  {configJSON.email}
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder={configJSON.placeHolderEmail}
                  type="text"
                  value={this.state.email}
                  name="email"
                  data-test-id="email"
                  onChange={this.handleOnChange}
                  onFocus={this.handleFocus}
                  disabled
                  className={classes.textField}
                />
                <Box>
                  {this.state.errors.email ? (
                    <Typography className={classes.errorMEessage}>
                      {this.state.errors.email}
                    </Typography>
                  ) : null}
                </Box>
                <Box className={classes.updateButton}>
                  <Button variant="contained" className={classes.buttonUpdate} data-test-id="updateProfile" onClick={this.userprofileUpdate}>
                    {configJSON.buttonUpdate}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };
  otpPanField = () => {
    return (
      <>
        <Typography variant="body2" align="left" style={{ margin: "0 0 10px 15px", color: "red" }}>
          {this.state.panOtpError}</Typography>
        <OTPInput
          otp={this.state.panOtp ? `${this.state.panOtp}` : ""}
          handleOtpChange={this.handleOtpChange}
          separator=""
          blocks={6}
        />
      </>
    );
  };
  defaultBody = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title" style={{ ...style.modelTitle, textAlign: "center", ...style.white }}>
          Verify Your Pan Number
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" align="left" style={{ ...style.modelSubtitle, ...style.white }}>Verification code sent to you on registerd mobile number</Typography>
          <Box component="div" display="flex" justifyContent="center" flexDirection="column" >
            { this.otpPanField()}
          </Box>
          <Box component="div" display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "95%", margin: "7px auto 12px auto" }}>
            <Typography align="right" variant="body2" style={style.timer}>
              {this.state.second > 0 && `Resend OTP after: 00:${this.state.second.toString().padStart(2, '0')}`}
            </Typography>
            <Typography align="right" data-test-id="resend-id" variant="button" style={{ ...style.resendBtn, textTransform: "none" }} onClick={this.handleResendOtp}>{this.state.second == 0 && "Resend code"}</Typography>
          </Box>
          <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
            <Button data-test-id="verify-id" onClick={this.handleVerifyOtp} style={{ ...style.submitBtn, backgroundColor: this.state.panOtp.length < 6 ? "grey" : "#2b664b", }} variant="contained" disabled={this.state.panOtp.length < 6}>
              {configJSON.verifyButton}
            </Button>
          </DialogActions>
        </DialogContent> </>
    )
  }
  successBody = () => {
    return (
      <Box component="div" display="flex" alignItems="center" flexDirection="column" sx={{ marginTop: "20px" }}>
        <img
          style={style.shield}
          src={Shield}
          alt="shield"
        />
        <DialogTitle id="alert-dialog-title" style={{ ...style.modelTitle, textAlign: "center", ...style.white }}>
          Verifed
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" align="center" style={{ ...style.subtitle1, margin: "0 25px 20px 25px" }}>{configJSON.verifiedPan}</Typography>
          {this.state.isOtpVerified && <DialogActions style={{ justifyContent: "center", flexDirection: "column" }}>
            <Button data-test-id="close-id" onClick={this.closePanModelUserprofile} style={{ ...style.submitBtn, backgroundColor: "#2b664b" }} variant="contained">
              Done
            </Button>
          </DialogActions>}
        </DialogContent>
      </Box>
    )
  }
  otpDialog = () => {
    return (
      <Dialog
        open={this.state.panOtpOpen}
        onClose={this.closePanModelUserprofile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "#1b222e", border: "1px solid #fff", borderRadius: "10px"
          }
        }}
        disableScrollLock={true}
      >
        {this.state.isOtpVerified  ? this.successBody() : this.defaultBody()}
      </Dialog>
    )
  }
  renderPanCardData = (classes: any) => {
    return (
      <Box>
        <Box className={classes.cardsDataFirst} onClick={this.panCardHandle} data-test-id="pan_drop">
          <Box className={classes.cards}>
            <img
              src={pancard}
              alt={configJSON.profile_icon}
              className={classes.docimgstyle}
            />
            <Typography variant="h6" className={classes.headingcards}>{configJSON.panH}</Typography>
          </Box>
          {!this.state.enablePanEditMode ? (
            <ExpandMoreIcon />
          ) : (

            <ChevronRightIcon />
          )}
        </Box>
        {this.state.enablePanEditMode ? (
          <Box className={classes.cardContainer} data-test-id="pancarddata">

            {this.state.userPancards && this.state.userPancards.map((item:any) => {
              return (
                <Box className={classes.cardContainerOne} >
                  <Box>
                    <Typography className={classes.fontData}>
                      {configJSON.pan}
                    </Typography>
                    <Typography className={classes.fontData}>
                      {configJSON.fullName}
                    </Typography>
                    <Typography className={classes.fontData}>
                      {configJSON.status}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography className={classes.fontData}>{item?.attributes?.profile?.attributes?.pan_number}</Typography>
                    <Typography className={classes.fontData}>
                      {item?.attributes?.profile?.attributes?.name}
                    </Typography>
                    <Typography className={classes.fontData}>Individual</Typography>
                  </Box>
                  <Box className={classes.panDelete}>
                    <Typography variant="subtitle1" className={classes.fontData}>
                      {item.attributes?.is_primary && configJSON.primary}
                    </Typography>
                    <Box className={classes.iconalign} >
                    <Tooltip title="Make Primary" arrow>
                        <Switch
                          checked={item.attributes?.is_primary}
                          className={classes.switchSetting}
                          onChange={() => this.handleChangeChecked(item?.attributes?.profile?.attributes?.pan_number, item?.attributes?.assessment_year?.assessment_year)}
                          disabled={item.attributes?.is_primary}
                          color="primary"
                          data-test-id="switch"
                          size="small"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Tooltip>
                      {!item.attributes?.is_primary && (
                        <Tooltip title="Delete Pan" arrow>
                          <DeleteOutlinedIcon
                            data-test-id={"delete" + item.id}
                            onClick={this.handlepanDelete.bind(this, item?.attributes.pan_profile_id)}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            })}


            <Box className={classes.buttonAlignment}>
              <Button className={classes.addPan} onClick={this.handleAddpanDetails} data-test-id="add_pan">{configJSON.AddPANData}</Button>
            </Box>
            {this.state.openPanCad &&
              <Dialog open={this.state.openPanCad} className={classes.drawer} data-test-id="add_pan_modal">
                <Box >
                  <Box className={classes.modelHeadingWrapper}>
                    <Typography className={classes.deductionsHeading}>{configJSON.AddPANcard}</Typography>
                    <CloseIcon onClick={this.handleclosePandetails} className={classes.cursorPointer} />
                  </Box>
                  <Box className={classes.pancardfields}>
                    <TextField id="standard-basic"
                      className={classes.textfield}
                      variant="outlined"
                      placeholder="Name"
                      name="name"
                      value={this.state.panDetails["name"]}
                      data-test-id="name_pan"
                      onChange={(e: { target: { name: string, value: string } }) => this.handleNameChangeInput(e)}
                    />
                    <Typography variant="body2" component="div" style={style.errors}>
                      {this.state.panDetailsError["name"]}
                    </Typography>
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      placeholder="Pan card number"
                      name="panNo"
                      data-test-id="panNo"
                      value={this.state.panDetails["panNo"]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePanChangeInput(e)}
                    />
                    <Typography variant="body2" component="div" style={style.errors}>
                      {this.state.panDetailsError["panNo"]}
                    </Typography>
                    <Button className={classes.getotp} onClick={this.handlePanDetailsSubmitcall} data-test-id="get_otp" >{configJSON.getotpButton}</Button>
                    <Typography style={{ textAlign: "right" }} variant="subtitle2"> {configJSON.otpmessage}</Typography>
                  </Box>
                </Box>
              </Dialog>}
          </Box>
        ) : null}
      </Box>
    );
  };
  renderTextFiled = (type: string, value: string, name: string, onChange: any, visibilityHandler: any, enablePasswordField: boolean, error: string | undefined,) => {
    const isPasswordVisible = enablePasswordField ? <VisibilityOff /> : <Visibility />;
    const { classes }: any = this.props;
    return (
      <>
        <TextField
          variant="outlined"
          placeholder={configJSON.passplaceHolder}
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          data-test-id={name}
          onFocus={this.handleFocus}
          className={classes.textFieldChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={visibilityHandler}
                  edge="end"
                  data-test-id={name + "s"}
                >
                  {isPasswordVisible}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box>
          {error && (
            <Typography className={classes.errorMessage}>
              {error}
            </Typography>
          )}
        </Box>
      </>
    );
  }
  renderChangePasswordData = (classes: any) => {
    return (
      <Box>
        <Box className={classes.cardsData} onClick={this.handlePasswordChange} data-test-id="pass_drop">
          <Box className={classes.cards}>
            <img src={lock} alt={configJSON.profile_icon} className={classes.docimgstyle} />
            <Typography variant="h6" className={classes.headingcards}>{configJSON.changePasscode}</Typography>
          </Box>
          {!this.state.enablePasswordEditMode ? (
            <ExpandMoreIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </Box>
        {this.state.enablePasswordEditMode ? (
          <Grid container className={classes.gridWrapperPassword}>
            <Grid item sm={4} xs={12} className={classes.passwordText}>
              <Typography className={classes.fontData}>
                {configJSON.current}
              </Typography>
              <Typography className={classes.fontData}>
                {configJSON.currentNew}
              </Typography>
              <Typography className={classes.fontData}>
                {configJSON.confirm}
              </Typography>
            </Grid>
            <Grid item sm={7}>
              {this.renderTextFiled(
                this.state.enablePasswordField ? "text" : "password",
                this.state.currentPassword,
                "currentPassword",
                this.handleOnChange,
                this.handleClickShowPassword,
                this.state.enablePasswordField,
                this.state.errorsPwd.currentPassword,

              )}
              {this.renderTextFiled(
                this.state.enableNewPasswordField ? "text" : "password",
                this.state.newPassword,
                "newPassword",
                this.handleOnChange,
                this.handleClickShowPasswordNew,
                this.state.enableNewPasswordField,
                this.state.errorsPwd.newPassword,


              )}
              {this.renderTextFiled(
                this.state.enableConfirmPasswordField ? "text" : "password",
                this.state.confirnNewPassword,
                "confirnNewPassword",
                this.handleOnChange,
                this.handleClickShowPasswordConfirm,
                this.state.enableConfirmPasswordField,
                this.state.errorsPwd.confirnNewPassword,


              )}
            </Grid>
            {/* <Grid item xs={4} /> */}
            <Grid item xs={12} className={classes.buttonarrangment}>

              <Box className={classes.updatePasswordButton}>
                <Button variant="contained" className={classes.changePassword} data-test-id="password_update" onClick={this.userprofilePassword}>
                  {configJSON.buttonChange}
                </Button>
                <Button variant="contained" className={classes.clear} data-test-id="password_clear_btn" onClick={this.clearPassword}>
                  {configJSON.buttonClear}
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    );
  };
  renderItrHistoryData = (classes: any) => {
    return (
      <Box className={classes.cardsData} onClick={this.navigateToItr} data-test-id="navigatetoItr">
        <Box className={classes.cards}>
          <img
            src={hamburger}
            alt={configJSON.profile_icon}
            className={classes.docimgstyle}
          />
          <Typography variant="h6" className={classes.headingcards}> {configJSON.itrHistory}</Typography>
        </Box>
      </Box>
    );
  };
  renderTransastionData = (classes: any) => {
    return (
      <Box className={classes.cardsData} onClick={this.navigateToTransaction} data-test-id="navigatetoTransaction">
        <Box className={classes.cards}>
          <img src={paper} alt={configJSON.profile_icon} className={classes.docimgstyle} />
          <Typography variant="h6" className={classes.headingcards}> {configJSON.transactionHistory}</Typography>
        </Box>
      </Box>
    );
  };
  renderButtons = (classes: any) => {
    return (
      <Box className={classes.userDataButtons}>
        <MakeaQuery open={this.state.open} handleModelClose={this.handleModelClose} navigation={undefined} id={""} data-test-id="close_modal" history={undefined} />

        <Button variant="contained" disableRipple className={classes.buttonMakeQuery} onClick={this.modalOpen} data-test-id="open_modal">
          {configJSON.makeAnQuery}
        </Button>
        <Button variant="contained" disableRipple className={classes.button}>
          {configJSON.fileItr}
        </Button>
      </Box>
    );
  };
  render() {
    const { classes }: any = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} className={classes.defaltValues}>
          <Snackbar anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} open={this.state.statusOpen ? this.state.statusOpen : this.state.statusOpenError} autoHideDuration={4000} onClose={this.handleMessageClose} >
            <Alert severity={this.state.statusOpen ? "success" : "error"}>
              {this.state.snakbarMessage}
            </Alert>
          </Snackbar>
          {this.otpDialog()}
          <BackgroundHeaderWeb title={configJSON.presonalinfo} onClick={this.navigatetoLandingpage} data-test-id="navigate_landingpage" />
          <Box className={classes.backgroundSubPaper}>
            <Box className={classes.backgroundPaper}>
              {this.renderprofileData(classes)}
              <Box className={classes.dropDownMain}>
                <Box className={classes.cardpadding}>
                  {this.renderPanCardData(classes)}
                  {this.renderChangePasswordData(classes)}
                  {this.renderItrHistoryData(classes)}
                  {this.renderTransastionData(classes)}
                  {this.renderButtons(classes)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}
const styles = {
  switchSetting: {
    "& .MuiSwitch-colorPrimary.Mui-disabled": {
      color: "#4CAF50"
    },
    " & .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#4CAF50",
      opacity: "0.6"

    },
  },
  textfield: {
    width: '100%'
  },
  defaltValues: {
    padding: 0,
    margin: 0
  },
  backgroundPaper: {
    background: "rgba(51, 113, 82, 0.0585664)",
    width: "59.722vw ",
    position: "relative" as const
  },
  backgroundSubPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  userIcon: {
    borderRadius: "100px 2px 2px 100px",
    transform: "translate(19.5%, -25%)",
    width: "48.125vw",
    padding: "0.2em 0 1em 0",
    background: "#FFFAED",
    display: "flex",
    justifyContent: "space-evenly",
    "@media(max-width:650px)": {
      borderRadius: "10px",
      transform: "translate(12.5%, -25%)",
    },
    "@media(max-width:410px)": {
      borderRadius: "10px",
      transform: "translate(12.5%, -20%)",
    },
    "@media(max-width:350px)": {
      borderRadius: "10px",
      transform: "translate(12.5%, -5%)",
    },
  },
  infowrapper: {
    "@media(mix-width:200px)": {
      display: "flex", flexDirection: "column", alignItems: "baseline"

    },
  },
  userEditPage: {
    transform: "translate(0%, -15%)",
    margin: " 0 2em 0 2em",
    background: "#fff",
    borderRadius: "12px",
    border: "0.5px solid #4D4D4D"
  },
  userData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:700px)": {
      flexWrap: "wrap",
    },
    "@media(max-width:350px)": {
      flexWrap: "wrap",
      textAlign: "center"
    },

  },
  buttonarrangment: {
    "@media(max-width:900px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  },
  userDataButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:700px)": {
      textAlign: "center",
      marginTop: '1em',
      display: "flex",
      justifyContent: "center",
      flexDirection: 'column',
      alignItems: "center",
    }
  },
  headingcards: {
    "@media(max-width:612px)": {
      fontSize: "0.7rem"
    },
    "@media(max-width:450px)": {
      fontSize: "0.6rem"
    },
  },
  iconalign: {
    marginTop: '0.7em',
    marginLeft: "0.4em",
    display: "flex",
    "@media(max-width:1042px)": {
      marginTop: '0.4em',
      justifyContent: "center"
    },
  },
  userAPiData: {
    marginLeft: "2em",
    "@media(max-width:612px)": {
      marginLeft: "0",
    },
  },
  iconeEdit: {
    marginTop: "1.4em",
    "@media(max-width:650px)": {
      textAlign: "center"
    },
  },
  imageRadius: {
    width: "12.361vw",
    height: "12.361vw",
    margin: "0 0 0 -7vw",
    borderRadius: "11.427vw",
    border: "1px solid #979797",



  },
  responsive: {
    "@media(max-width:650px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "1.5em"
    },

  },
  imageRadiusEdit: {
    width: "12.361vw",
    height: " 12.361vw",
    borderRadius: "11.427vw",
    border: "1px solid #979797"
  },
  cardsData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFAED",
    marginTop: "1.5em",
    height: "46px",
    cursor: "pointer"
  },
  cardsDataFirst: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFAED",
    height: "46px",
    cursor: "pointer",

  },
  panDelete: {
    display: "flex",
    "@media(max-width:600px)": {
      display: "block",
      textAlign: "center"
    },
  },
  cardpadding: {
    padding: " 0 1em 0 2em"
  },
  button: {
    background: "#337152",
    width: "220px",
    border: "1.2px solid #243763",
    borderRadius: "4px",
    margin: "1em 1em 1em 0",
    '&:hover': {
      background: "#337152",
    },
    color: "#FFF",
    fontFamily: "Helvetica",
    fontSize: "16px",
    marginLeft: '1em',
    "@media(max-width:600px)": {
      fontSize: "12px",
      marginLeft: '0',
      margin: "1em 0 1em 0",
      width: "150px",
    },
  },
  buttonMakeQuery: {
    background: "#FFF",
    width: "220px",
    border: "1.2px solid #243763",
    borderRadius: "4px",
    '&:hover': {
      background: "#FFF",
    },
    color: "#337152",
    fontFamily: "Helvetica",
    fontSize: "16px",
    "@media(max-width:600px)": {
      fontSize: "12px",
      width: "150px",
    },
  },
  cards: {
    display: "flex",
    alignItems: "center"
  },
  docimgstyle: {
    width: "24px",
    height: "24px",
    padding: "0 12px 0 14px",
    cursor: "pointer"
  },
  textField: {
    "& input": {
      padding: "9px 10px",
      borderRadius: "8px",
      border: "1px solid #grey"
    },
    width: "100%"
  },
  textFieldChange: {
    "& input": {
      padding: "9px 10px",
      borderRadius: "8px",
      border: "1px solid #grey"
    },
    width: "100%",
    marginTop: "0.7em",
  },
  errorMessage: {
    paddingTop: "5px",
    color: "red",
    fontFamily: "Nunito",
    fontSize: " 12px"
  },
  subHeading: {
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "16px"
  },
  backgroundWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  formdatavalidate: {
    display: "flex",
    flexDirection: "column" as const
  },
  gridWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "1em 0 1em 0"
  },
  buttonUpdate: {
    background: "#337152",
    width: "120px",
    '&:hover': {
      background: "#337152",
    },
    borderRadius: "4px",
    color: "#FFF",
    fontFamily: "Helvetica",
    fontSize: "16px",
  },
  updateButton: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center"
  },
  updatePasswordButton: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "right",
    "@media(max-width:900px)": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center"
    },


  },
  changePassword: {
    borderRadius: "4px",
    background: "#337152",
    marginRight: "1em",
    height: "26px",
    '&:hover': {
      background: "#337152",
    },
    color: "#FFF",
    fontFamily: "Helvetica",
    fontSize: "16px",
    "@media(max-width:705px)": {
      fontSize: "12px",
      marginRight: "0",
    },
    "@media(max-width:385px)": {
      fontSize: "5px",
      marginRight: "0",
    },

  },
  clear: {
    background: "#FFF",
    border: "1.2px solid #243763",
    borderRadius: "4px",
    width: '110px',
    height: "26px",
    '&:hover': {
      background: "#FFF",
    },
    color: "#337152",
    fontFamily: "Helvetica",
    fontSize: "16px",
    "@media(max-width:600px)": {
      fontSize: "12px",
      marginTop: '0.8em',
    },
    "@media(max-width:900px)": {
      fontSize: "12px",
      marginTop: '0.8em'
    },
    "@media(max-width:385px)": {
      fontSize: "5px",
      marginRight: "0.8",
      width: '7.639vw',
    },
  },
  dropDownMain: {
    width: "100%",
    height: "100%",
  },
  cardContainer: {
    background: "#fff",
    padding: "1em 1em 1em 1.5em",
    display: 'flex',
    flexDirection: "column" as const,
    gap: '12px'
  },
  cardContainerOne: {
    background: "rgba(255, 250, 237, 0.70)",
    padding: "0.2em 1em 1em 1em",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "1px",
    "@media(max-width:470px)": {
      display: "block",
      textAlign: "center"
    },
  },
  gridWrapperPassword: {
    display: "flex",
    justifyContent: "space-between",
    background: "white",
    padding: "1em"
  },
  passwordText: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-around",
    paddingLeft: "2em",
    "@media(max-width:600px)": {
      display: "flex",
      alignItems: "center",
      padding: '0'
    },
  },
  fontData: {
    fontFamily: "Helvetica",
    color: "#4D4D4D",
    fontWeight: 400,
    fontSize: "16px",
    marginTop: "0.7em",
    "@media(max-width:1029px)": {
      fontSize: "12px",
    },
  },
  userDataProfileText: {
    color: "#4D4D4D",
    fontSize: "20px",
    fontFamily: "Helvetica",
    marginTop: "0.5em",
    "@media(max-width:650px)": {
      fontSize: "10px",
    },
    "@media(max-width:948px)": {
      fontSize: "12px",
    },
  },
  imageEditIcon: {
    width: "1.389vw",
    height: "1.389vw",
    padding: " 0.5em",
    background: "#337152",
    transform: "translate(9vw, -3vw)",
    borderRadius: " 40px",
    border: "1px solid #337152",
    "@media(max-width:650px)": {
      transform: "translate(4vw, -3vw)",
    },
  },
  addPan: {
    borderRadius: "4px",
    background: "#337152",
    padding: "1em 3em 1em 3em",
    height: "26px",
    '&:hover': {
      background: "#337152",
    },
    color: "#FFF",
  },
  buttonAlignment: {
    display: "flex",
    marginTop: '0.2em',
    justifyContent: "end"
  },
  drawer: {

    " & .MuiDialog-paperScrollPaper": {
      borderRadius: '1em',
      maxWidth: "100%",
      width: "500px",
      padding: '2em',
    }
  },

  modelHeadingWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '1em'
  },
  deductionsHeading: {
    fontFamily: "Nunito",
    fontSize: "22px",
    fontWeight: 500,
  },
  cursorPointer: {
    cursor: "pointer"
  },
  pancardfields: {
    display: "flex",
    flexDirection: "column" as const,
    gap: '10px',
  },
  getotp: {
    borderRadius: "4px",
    background: "#337152",
    padding: "1.3em",
    height: "26px",
    '&:hover': {
      background: "#337152",
    },
    color: "#FFF",
  }
};
const style = {

  loginBtn: {
    backgroundColor: "#2b664b",
    color: "#fff",
    width: "100%",
    margin: '10px 0px'
  },
  otpBrif: {
    color: '#cfd1d4', fontSize: "12px", padding: '5px 0px 20px 0px'
  },
  subTitle: {
    margin: "10px 0 0 0"
  },
  submitBtn: {
    width: "85%", marginBottom: "5px", color: "#fff"
  },
  errors: {
    color: "red",
    fontSize: "0.75rem",
    // marginTop: "0.2rem"
  },
  resendBtn: { color: "#7292cd", textDecoration: "underLine", cursor: "pointer" },
  subtitle1: { color: "#fff" },
  timer: { color: "#fff" },
  backBtn: { marginTop: "5px", color: "#fff" },
  error: { margin: "0 0 10px 15px", color: "red" },
  shield: { width: "75px", height: "75px" },
  white: {
    color: "#fff",
  },
  fullWidth: {
    width: "100%",
    height: "100%"
  },
  modelSubtitle: {
    maxWidth: "435px",
    marginLeft: "5px"
  },
  modelTitle: {
    margin: "2px 0 0 0"
  },
  titlebody: {
    color: "#acafb3", margin: "5px 0 0 0", fontSize: "1.0rem"
  },
  title: {
    margin: "2rem 0 0 0"
  },
};
export default withStyles(styles)(UserProfileBasicBlock);
// Customizable Area End
