Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostMethodtype = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "assessmenttest";
exports.labelBodyText = "assessmenttest Body";

exports.navToDashboard="Menu/Dashboard"
exports.login="login"
exports.UserProfileBasicBlock="UserProfileBasicBlock"

exports.btnExampleTitle = "CLICK ME";
exports.navToDashboard="Menu/Dashboard"
exports.login="login"
exports.UserProfileBasicBlock="UserProfileBasicBlock"
exports.getResidentailCalculatorAPIEndpoint="bx_block_assessmenttest/residential_calculators/question_answer"
exports.prevResidentailCalculatorAPIEndpoint="bx_block_assessmenttest/residential_calculators/prev_question"
exports.ResidentialQuizAPIEndpoint="bx_block_assessmenttest/residential_calculators/residental_status"
exports.authToken = "authToken";
// Customizable Area End